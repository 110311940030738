/* Disable the text underline. */
a {
  text-decoration: none !important;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

/*
@import "./styles/error.scss";
@import "./styles/legal.scss";
@import "./styles/homepage.scss";
@import "./styles/navigation.scss";
@import "./styles/lobby.scss";
@import "./styles/game.scss";
@import "./styles/loading.scss";
@import "./styles/waiting.scss";
@import "./styles/captions.scss";
@import "./styles/playerBanner.scss";
@import "./styles/choices.scss";
@import "./styles/why.scss";
@import "./styles/voting.scss";
@import "./styles/winner.scss";
@import "./styles/about.scss";

// New styling stuff.
@import "./newStyles/tasks.scss";
@import "./newStyles/ingesters.scss";
*/
/*
.c-App {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #f4f8fe;
}

.App {
  width: 960px;
  margin-left: calc(50% - 480px);
  height: 100%;
  position: absolute;
    text-align: center;

}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


*/

